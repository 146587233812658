import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Header = ({ data }) => {
  const { logo } = data;

  const [mobileToggle, setMobileToggle] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isSticky, setIsSticky] = useState(false);

  const handleMobileToggle = () => {
    setMobileToggle(!mobileToggle);
  };

  useEffect(() => {
    const handleScroll = () => {
      const windowTop = window.scrollY || document.documentElement.scrollTop;

      if (windowTop >= headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      setLastScrollTop(windowTop);
    };

    const headerHeight =
      document.querySelector(".st-sticky-header").offsetHeight + 100;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky, lastScrollTop]);

  return (
    <header
      className={`st-site-header st-style1 st-sticky-header ${
        isSticky ? "st-sticky-active" : ""
      }`}
    >
      <div className="st-main-header">
        <div className="container">
          <div className="st-main-header-in">
            <div className="st-main-header-left">
              <Link to="" className="st-site-branding">
                <img src={logo} alt={logo} />
              </Link>
            </div>
            <div className="st-main-header-right">
              <div className="st-nav">
                <ul
                  className={`st-nav-list st-onepage-nav ${
                    mobileToggle ? "d-block" : "none"
                  }`}
                >
                  <li>
                    <ScrollLink
                      to="home"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      Home
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="services"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      Our Services
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="eligibility"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      Eligibility
                    </ScrollLink>
                  </li>
                  {/* <li>
                    <ScrollLink
                      to="getapproved"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      Get Approved
                    </ScrollLink>
                  </li> */}
                  <li>
                    <ScrollLink
                      to="about"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      About Us
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="careers"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      Careers
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="contact"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      Contact Us
                    </ScrollLink>
                  </li>
                </ul>
                <div
                  className={`st-munu-toggle ${
                    mobileToggle ? "st-toggle-active" : ""
                  } `}
                  onClick={handleMobileToggle}
                >
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
