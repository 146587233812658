import React from "react";
import Contact from "../Components/Contact/Contact";
import Department from "../Components/Department/Department";
import About from "../Components/About/About";
import TestimonialSlider from "../Components/Slider/TestimonialSlider";
import Hero from "../Components/Hero/Hero5";

const heroData = {
  bgImg: "images/hero-bg.jpg",
  bgShape: "shape/hero-shape.png",
  sliderImages: [
    {
      img: "images/hero-img.png",
    },
    {
      img: "images/hero-img1.png",
    },
    {
      img: "images/hero-img2.png",
    },
    {
      img: "images/hero-img.png",
    },
    {
      img: "images/hero-img1.png",
    },
    {
      img: "images/hero-img2.png",
    },
  ],
  title: [
    "Personal Care",
    "Meal Prep",
    "Companionship",
    "Chair Transfer",
    "Transportation",
    "Light Cleaning",
  ],
};

const aboutData = {
  title: "Experts in Compassionate Home Health Care",
  subTitle: `Our mission is to create a safe and joyful environment for those in need of care. We aim to uplift individuals who may feel alone or helpless, reminding them of their value. Our goal is to ensure that your loved ones feel recognized and appreciated while fostering a community of friendship and joy. We welcome people of all ages and backgrounds, providing the care and treatment they deserve to feel at home.<br /><br />  Our comprehensive care plans are tailored to meet the unique needs of each individual. Whether it's assistance with daily activities, companionship, or emotional support, we are here to help. With a focus on improving quality of life, we strive to create a nurturing environment where our clients can thrive and maintain their independence.`,

  avater: {
    img: "images/unnamed.jpg",
    name: "Yolanda Freeman",
    designation: "Founder & Director",
  },
  timeTable: [
    {
      day: "Monday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Tuesday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Wednesday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Thursday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Friday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Saturday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Sunday",
      startTime: "9:00",
      endTime: "5:00",
    },
  ],
  contact: "678-368-0761",
};

const testimonialData = [
  {
    img: "icons/icon1.svg",
    name: "Insurances and Benefits are Welcomed.",
    designation: "",
    subTitle:
      "Private Pay, medicaid, medicare, Veterans insurance. This ensures that you receive the support and care you need without financial strain. Contact us for more information.",
  },
  {
    img: "icons/icon2.svg",
    name: "Live in Georgia",
    designation: "",
    subTitle:
      "We proudly serve residents of Georgia. If you live in the state, you qualify for our home health care services.",
  },
  {
    img: "icons/icon4.svg",
    name: "Need Help",
    designation: "",
    subTitle:
      "You are eligible if your disability prevents you from doing some daily tasks on your own. We are happy to assist you.",
  },

  // {
  //   img: "images/avatar2.png",
  //   name: "Ralph Jones",
  //   designation: "Executive",
  //   subTitle:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  // },
  // {
  //   img: "images/avatar3.png",
  //   name: "Francis Jara",
  //   designation: "Biographer",
  //   subTitle:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  // },
  // {
  //   img: "images/avatar4.png",
  //   name: "David Baer",
  //   designation: "UX Designer",
  //   subTitle:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  // },
];

const faqData = {
  title: "Just Answer the Questions",
  img: "images/faq-img.png",
  bgImg: "shape/faq-bg.svg",
  faqItems: [
    {
      title: "What is Medi solution?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "How do I get a refill on my prescription?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "How competent our total treatment?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "If I get sick what should I do?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "What is emergency cary to your hospital?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
  ],
};

const mapLocationURL =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d193175.30893635444!2d-74.373409!3d40.841927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy%20Meadows%20Wetlands!5e0!3m2!1sen!2sbd!4v1701067943819!5m2!1sen!2sbd";

const Home2 = () => {
  return (
    <>
      <Hero data={heroData} />
      <Department />
      <hr />
      <TestimonialSlider data={testimonialData} />
      <About data={aboutData} />
      <Contact />
    </>
  );
};

export default Home2;
