import React, { useEffect, useState } from "react";
import Social from "../Social/Social";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const Footer = ({ data, varient }) => {
  const { logo, subTitle, bgImg, links } = data;
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    setScrollPosition(currentPosition);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer
      className={`st-site-footer st-sticky-footer st-dynamic-bg ${
        varient ? varient : ""
      }`}
      style={{ backgroundImage: `url(${bgImg})` }}
      id="contact"
    >
      <div className="st-main-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="st-footer-widget">
                <div className="st-text-field">
                  <img src={logo} alt={logo} className="st-footer-logo" />
                  <div className="st-height-b25 st-height-lg-b25" />
                  <div className="st-footer-text">
                    Where Love, Grace, and Peace Reside.
                  </div>
                  <div className="st-height-b25 st-height-lg-b25" />
                  {/* <Social data={links} /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="st-footer-widget">
                <h2 className="st-footer-widget-title">Contact Us</h2>
                <ul className="st-footer-contact-list st-mp0">
                  <li>
                    <span className="st-footer-contact-title">Address:</span>{" "}
                    1775 Parker Road Conyers GA 30094
                  </li>
                  <li>
                    <span className="st-footer-contact-title">Email:</span>{" "}
                    apieceofmindpc@gmail.com
                  </li>
                  <li>
                    <span className="st-footer-contact-title">Phone:</span>{" "}
                    678-368-0761
                  </li>
                </ul>
              </div>
            </div>
            {/* .col */}
          </div>
        </div>
      </div>
      <div className="st-copyright-wrap">
        <div className="container">
          <div className="st-copyright-in">
            <div className="st-left-copyright">
              <div className="st-copyright-text">
                Copyright {currentYear}. Design by{" "}
                {<a href="https://www.mindbush.com">Mindbush Digital.</a>}
              </div>
            </div>
            <div className="st-right-copyright">
              <div
                id="st-backtotop"
                style={{ scale: `${scrollPosition >= 100 ? "1" : "0"}` }}
                onClick={scrollToTop}
              >
                <Icon icon="fa6-solid:angle-up" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
